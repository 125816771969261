import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';
import Link from 'gatsby-link';
import ShareContentModal from '@components/molecules/ShareContentModal/ShareContentModal';
import DeleteCollectionModal from '@components/molecules/DeleteCollectionModal/DeleteCollectionModal';

import './CollectionCard.scss';

const CollectionCard = ({ 
  title, 
  url, 
  numberOfItems, 
  location,
  collection
}) => {

  const shareBtn = useRef(null);
  const deleteBtn = useRef(null);

  const [isOpen, setIsOpen] = useState(false);
  const open = () => setIsOpen(true);
  const close = () => {
    setIsOpen(false);
    shareBtn.current.focus();
  };

  const [deleteIsOpen, setDeleteIsOpen] = useState(false);
  const deleteOpen = () => setDeleteIsOpen(true);
  const deleteClose = () => {
    setDeleteIsOpen(false);
    deleteBtn.current.focus();
  };

  return (
    <>
      <div className="mars-collection-card mars-padding-y--sm mars-padding-x--md">
        <div className="mars-collection-card__details">
          <h2 className="mars-collection-card__title mars-delta">{title}</h2>
          <span className="mars-collection-card__items mars-body-small">
            {numberOfItems} items
          </span>
        </div>
        <div className="mars-collection-card__share">
          <button
            ref={shareBtn}
            className="mars-button mars-button--secondary"
            onClick={open}>
            <span className="mars-button__text">Share</span>
          </button>
        </div>
        <Link to={url} className="mars-button">
          View collection
        </Link>
        <button 
          ref={deleteBtn} 
          onClick={deleteOpen} 
          className="mars-button mars-margin-left--sm">
            Delete collection
        </button>
      </div>
      <ShareContentModal
        shareURL={location.origin + url}
        isOpen={isOpen}
        onDismiss={close}
      />
      <DeleteCollectionModal
        isOpen={deleteIsOpen}
        onDismiss={deleteClose}
        onDelete={deleteClose}
        collection={collection}
      />
    </>
  );
};

export default CollectionCard;

CollectionCard.propTypes = {
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
  numberOfItems: PropTypes.number,
  location: PropTypes.object.isRequired,
  collection: PropTypes.object
};
