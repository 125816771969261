import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import actions from '@state/actions';
import Layout from '@components/Layout';
import SEO from '@components/SEO';
import CollectionsHero from '@components/organisms/CollectionsHero/CollectionsHero';
import CollectionCard from '@components/molecules/CollectionCard/CollectionCard';
import GTMPageLoad from '@components/atoms/GTM/GTMPageLoad';

import '@styles/site.scss';

const MyCollections = ({ location }) => {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(actions.getCollections());
  }, []);

  const collections = useSelector((state) => state.collections.collections) || [];

  const collectionsListTitle = () => {
    if (collections.length == 1) {
      return `${collections.length} collection`;
    }
    return `${collections.length} collections`;
  };

  const collectionsListItems = collections.map((collection, index) => {
    return (
      <li
        index={index}
        key={`mars-collection-item--${collection.id}`}
        className="mars-my-collection-list__list-item">
        <CollectionCard
          title={collection.title}
          url={`/collection?id=${collection.id}`}
          numberOfItems={collection.totalItems}
          location={location}
          collection={collection}
        />
      </li>
    );
  });

  return (
    <Layout location="" title="My Collections">
      <SEO title="My Collections" />
      <GTMPageLoad
        {...{
          page_type: `my-collections`,
          page_taxonomy_field: `My Collections`,
          page_name: `My Collections`
        }}
      />
      <CollectionsHero
        title="My Collections"
        description="Your collections are a way of curating research into themes and projects relevant to you. Share collections with your team and start growing your next big idea."
      />

      <div className="mars-container mars-margin-y--xxl">
        <p className="mars-body-large">{collectionsListTitle()}</p>
        <ul className="mars-collection-card-list">{collectionsListItems}</ul>
      </div>
    </Layout>
  );
};

export default MyCollections;
