import React from 'react';
import { useDispatch } from 'react-redux';
import actions from '@state/actions';
import Modal from '@components/molecules/Modal/Modal';

const DeleteCollectionModal = ({ isOpen, onDismiss, onDelete, collection }) => {

  const dispatch = useDispatch();

  const deleteTheCollection = () => {
    dispatch(
      actions.removeCollection({collection})
    );
    dispatch(
      actions.getCollections()
    );
    onDelete();
  };

  const onKeyUp = (e) => {
    if (e.keyCode === 13) { // Enter
      onDismiss();
    }
  }

  return (
    <Modal
      isOpen={isOpen}
      onDismiss={onDismiss}
      className="mars-modal--dialog mars-text--center">
      <p className="mars-body">
        Are you sure you want to delete this collection?
      </p>
      <div className="mars-form-row">
        <button
          onClick={deleteTheCollection}
          className="mars-button mars-button--wide">
          Yes, delete
        </button>
      </div>
      <button className="mars-button mars-button--secondary mars-button--wide" onKeyUp={onKeyUp}>
        <span className="mars-button__text" onClick={onDismiss}>
          Cancel
        </span>
      </button>
    </Modal>
  );
};

export default DeleteCollectionModal;
