import React from 'react';
import { useDispatch } from 'react-redux';
import { useStaticQuery, graphql } from 'gatsby';
// import Img from 'gatsby-image';
import { GatsbyImage, getImage } from 'gatsby-plugin-image';
import actions from '@state/actions';
import PropTypes from 'prop-types';
import MarsIcon from '@components/atoms/MarsIcon/MarsIcon';

import './CollectionsHero.scss';

const CollectionsHero = ({ title, description, image, image2 }) => {
  const data = useStaticQuery(graphql`
    query MyCollectionsImages {
      image1: file(
        relativePath: { eq: "assets/collections-hero/collections-hero-01.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 300, placeholder: BLURRED)
        }
      }

      image2: file(
        relativePath: { eq: "assets/collections-hero/collections-hero-02.jpg" }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, width: 300, placeholder: BLURRED)
        }
      }
    }
  `);

  const dispatch = useDispatch();

  const openCreateCollection = () => {
    dispatch(actions.openCreateCollection());
  };

  return (
    <>
      <header className="mars-collections-hero">
        <div className="mars-container mars-grid mars-grid--2-cols">
          <div className="mars-collections-hero__content mars-padding-y--sm">
            <h1 className="mars-alpha mars-margin-bottom--xs mars-text-colour--brand1">
              {title}
            </h1>
            <p className="mars-collections-hero__description mars-body-large mars-margin-bottom--md">
              {description}
            </p>
            <button
              className="mars-button mars-button--brand4"
              onClick={openCreateCollection}>
              <MarsIcon icon="save" width="1rem" height="1rem" />
              <span>Add collection</span>
            </button>
          </div>
          <div className="mars-collections-hero__image-container">
            <MarsIcon
              icon="dots"
              width="220px"
              height="175px"
              className="mars-collections-hero__shape mars-icon--fill mars-icon--brand4"
            />
            {data && (
              <>
                {/* <Img
                  className="mars-collections-hero__image"
                  fluid={data.image1.childImageSharp.fluid}
                  alt=""
                />
                <Img
                  className="mars-collections-hero__image"
                  fluid={data.image2.childImageSharp.fluid}
                  alt=""
                /> */}
                <GatsbyImage
                  className="mars-collections-hero__image"
                  image={getImage(data.image1)}
                  alt=""
                />
                <GatsbyImage
                  className="mars-collections-hero__image"
                  image={getImage(data.image2)}
                  alt=""
                />
              </>
            )}
          </div>
        </div>
      </header>
    </>
  );
};

export default CollectionsHero;

CollectionsHero.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired
};
